<template>
  <div class="home">
    <section class="hero hero-color">
      <div class="hero-body">

        <div class="columns">
          <div class="column is-one-third">
            <img src="@/assets/inverted_transparent_logo_small.png"/>
          </div>
          <div class="column is-one-third">
            <p class="title">
              Prime Mover Electrical Inc.
            </p>
            <p class="subtitle">
              Yarmouth NS
            </p>  
          </div>
          <div class="column is-one-third">
            &nbsp;
          </div>
        </div>
      </div>
    </section>

    <div>
      <slider ref="slider" :options="options" @slide='slide' @tap='onTap' @init='onInit'>
        <slideritem v-for="(item,index) in someList" :key="index" :style="item.style">
          <img :src="getImgUrl(item)" style="height: 300px; padding-bottom: 10px; padding-top: 10px;"/>         
        </slideritem>
        <div slot="loading">loading...</div>
      </slider>
    </div>

    <div class="columns pt-4 pr-4 pl-4">
      <div class="column">
        <p class="is-size-4 p-2">
          We provide electrical services to the Tri-Counties.
        </p>
        <p class="is-size-4 p-2">
          Byron is a <a href="https://www.nsapprenticeship.ca/tradespersons/trade-certificates" target="new">Red Seal</a> electrician with 11 years of experience in the trade and 22 years experience in various trades.
        </p>
        <p class="is-size-4 p-2">
          Types of electrical work include residential, commercial, and heat pump installation.  We sell <b>Tempstar Heat Pumps</b> with a 10 year warranty.
        </p>
        <p class="is-size-4 p-2">
          <i class="fas fa-phone"></i> <a href="tel: 902-740-9104">902-740-9104</a> or <a href="tel: 902-307-2614">902-307-2614</a>
        </p>
        <p class="is-size-4 p-2">
          <i class="fas fa-envelope"></i> <a href="mailto: contact@pmelectrical.ca">contact@pmelectrical.ca</a>
        </p>
        <p class="is-size-4 p-2">
           (nous parlons francais)
        </p>
        <p class="is-size-4 p-2">
          <a href="https://g.page/r/Cc2jIKKOSJuEEAI/review" target="new">Click here for reviews!</a>
        </p>
      </div>
    </div>
 
  </div>
</template>

<style scoped>
.hero-color {
  background-color: black;
  color: white;
}
.title {
  color: white;
}
.subtitle {
  color: whitesmoke;
}
</style>

<script>
// @ is an alias to /src
//import HelloWorld from '@/components/HelloWorld.vue'
import { slider, slideritem } from 'vue-concise-slider'// import slider components

export default {
  name: 'HomeView',
  data () {
    return {
      publicPath: process.env.BASE_URL,
      //Image list
      someList:[],
      //Sliding configuration [obj]
      options: {
        currentPage: 0,
        thresholdDistance:500,
        thresholdTime:100,
        autoplay:2500,
        loop:true,
        direction:'horizontal',
        loopedSlides:1,
        slidesToScroll:1,
        timingFunction: 'ease',
        speed: 1500
      }      
    }
  },
  components: {
    slider,
    slideritem   
    /*HelloWorld*/
  },
  methods: {
    getImgUrl(item) {
     return require('../assets/photos/'+item.image)
    },
    // Listener event
    slide: function (data) {
      console.log(data)
    },
    onTap: function (data) {
      console.log(data)
    },
    onInit: function (data) {
      console.log(data)
    }
  },
  mounted () {
    let that = this
    setTimeout(function () {
      that.someList = [
        {
          image: 'photo1.png',
          style: {
            'background': 'red'
          }
        },
        {
          image: 'photo2.png',
          style: {
            'background': 'black'
          }
        },
        {
          image: 'photo3.png',
          style: {
            'background': 'blue'
          }
        },
        {
          image: 'photo4.png',
          style: {
            'background': 'red'
          }
        },
        {
          image: 'photo5.png',
          style: {
            'background': 'black'
          }
        }
      ]
    }, 2000)
  }
}
</script>
